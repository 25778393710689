import React from "react";
import { graphql } from "gatsby";
import Obfuscate from "react-obfuscate";

import Layout from "components/bespoke/Layout";
import SocialLinks from "components/SocialLinks";

export const query = graphql`
  {
    wp {
      page(id: "/bespoke/contact/", idType: URI) {
        id
        title
        slug
        bespokeContactPage {
          addressTitle
          mainTitle
          tagline
          formTitle
        }
      }
      themeGeneralSettings {
        globalOptions {
          address
          email
          phone
          twitter
          linkedin
          instagram
        }
      }
    }
  }
`;

const Contact = ({ data }) => {
  const { title } = data.wp.page;
  const pageData = data.wp.page.bespokeContactPage;
  const generalFields = data.wp.themeGeneralSettings.globalOptions;
  return (
    <Layout title={title}>
      <div className="bg-firefly">
        <header className="py-12 md:py-16 text-white">
          <h1 className="text-3xl md:text-5xl text-center font-bold uppercase">
            {pageData.mainTitle}
          </h1>
        </header>
        <div className="md:container md:mx-auto bg-white text-firefly text-center py-10 mb-auto">
          <div className="flex flex-col items-center md:gap-16">
            <div className="px-6 xl:px-0">
              <h3 className="uppercase tracking-wider  font-bold text-2xl">
                {pageData.formTitle}
              </h3>
              <div className="mt-4 flex flex-col gap-4 text-sm">
                <p className="mx-auto w-9/12">{pageData.tagline}</p>
                <form action="" className="flex flex-col gap-4">
                  <input
                    className="bg-gray-200 px-4 py-2 focus:ring-1 focus:outline-none ring-cinnabar-500"
                    type="text"
                    placeholder="Name"
                    required
                  />
                  <input
                    className="bg-gray-200 px-4 py-2 focus:ring-1 focus:outline-none ring-cinnabar-500"
                    type="email"
                    placeholder="Email"
                    required
                  />
                  <input
                    className="bg-gray-200 px-4 py-2 focus:ring-1 focus:outline-none ring-cinnabar-500"
                    type="text"
                    placeholder="Subject"
                    required
                  />
                  <textarea
                    className="bg-gray-200 px-4 py-2 focus:ring-1 focus:outline-none ring-cinnabar-500"
                    name=""
                    id=""
                    cols="30"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:container mx-6 md:mx-auto bg-white text-firefly text-center pt-0 pb-10 md:py-10 mb-auto">
        <div className="flex flex-col items-center md:gap-16">
          <div className="space-y-6">
            <div>
              <div className="uppercase text-xl font-bold tracking-wider mb-2">
                {pageData.addressTitle}
              </div>
              <div className="font-light">{generalFields.address}</div>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-12 font-light">
              <div className={``}>
                T:{" "}
                <Obfuscate
                  className="ml-2 hover:underline"
                  tel={generalFields.phone}
                />
              </div>
              <div className={``}>
                E:{" "}
                <Obfuscate
                  className="ml-2 hover:underline"
                  email={generalFields.email}
                  headers={{
                    subject: "Enquiry",
                  }}
                />
              </div>
            </div>
            <SocialLinks
              twitter={generalFields.twitter}
              linkedin={generalFields.linkedin}
              instagram={generalFields.instagram}
              label={`Follow us`}
              labelColor={`text-firefly`}
              align={`justify-center`}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
