import React from "react";
import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";

const SocialLinks = ({
  label,
  labelColor,
  align,
  instagram,
  twitter,
  linkedin,
}) => {
  return (
    <div className="">
      <div
        className={`${
          labelColor ? labelColor : "text-skin-accent"
        } font-semibold text-sm`}
      >
        {label}
      </div>
      <div className={`mt-2 flex space-x-4 items-center ${align}`}>
        <a target="_blank" rel="noreferrer" href={twitter}>
          <FaTwitter />
        </a>
        <a target="_blank" rel="noreferrer" href={linkedin}>
          <FaLinkedinIn />
        </a>
        <a target="_blank" rel="noreferrer" href={instagram}>
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
